import React, { useState, useEffect } from 'react';

import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';

import Fade from 'react-reveal/Fade';

const CustomNavbar = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);
  

  // const linkStyle = {color: "white", cursor: "pointer", padding: "8px"};

  return (
    <header className="header">
    <Container>
          <nav className="nav-bar">

            <Fade top={isDesktop} bottom={isMobile} duration={800} distance="30px">
              <span className="cta-btn cta-btn--nav">
                <Link to="about" smooth duration={1000} className="nav-links">
                  About
                </Link>
              </span>
            </Fade>
            <Fade top={isDesktop} bottom={isMobile} duration={800} delay={250} distance="30px">
              <span className="cta-btn cta-btn--nav"> 
                <Link to="projects" smooth duration={1000} className="nav-links">
                    Projects
                </Link>
              </span>
            </Fade>
            <Fade top={isDesktop} bottom={isMobile} duration={800} delay={500} distance="30px">
              <span className="cta-btn cta-btn--nav">
                <Link to="contact" smooth duration={1000} className="nav-links">
                    Contact
                </Link>
              </span>
            </Fade>
            <Fade top={isDesktop} bottom={isMobile} duration={800} delay={750} distance="30px">
              <a className="cta-btn cta-btn--nav-res" href="https://drive.google.com/file/d/1AJEnMbpv7-9iGrv-zGPDguMSwUw9ZGTp/view">
                Resume
              </a>
            </Fade>
          </nav>
        </Container>
      </header>
  );
};

export default CustomNavbar;