import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email } = contact;
  const { networks } = contact;

  return (
    <section id="contact">
      <Container>
        <Title title="Contact" />
        <Fade bottom duration={700} delay={600} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              {cta}
            </p>
            <p className="contact-wrapper__text">
              <Fade bottom duration={1000} delay={500} distance="30px">
                <a href='https://www.linkedin.com/in/arhumkhan/'>
                  <i className={`fa fa-linkedin`} />
                </a>
              </Fade>
              <Fade bottom duration={1000} delay={700} distance="30px">
                <a href='https://github.com/arhumkhan'>
                  <i className={`fa fa-github`} />
                </a>
              </Fade>
              <Fade bottom duration={1000} delay={900} distance="30px">
                <a href='mailto:khan.arhum.a@gmail.com'>
                  <i className={`fa fa-envelope-square`} />
                </a>
              </Fade>
            </p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={`mailto:${email}`}
            >
              {btn}
            </a>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
